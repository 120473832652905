import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';
//

export function enviarPlanilhaPerformance(data) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().adicionarPlanilhaPerformance, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          tipo: "sucesso",
          mensagem: resp.data
        });
      })
      .catch((erro) => {
        reject(erro.response.data.erros);
        if (erro.response.data.message) {
          store.commit("SET_MODAL", {
            ativado: true,
            tipo: "erro",
            mensagem: erro.response.data.message
          });
          return
        }
        if (erro.response.status == '500') {
          store.commit("SET_MODAL", {
              ativado: true,
              tipo: "erro",
              mensagem:
                "Erro ao processar a planilha, verifique as informações e tente novamente"
            });
            return
          } 
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function listarPerformances(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(`${_rotasAPI().listarPerformancesApuracao}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit('promocoes/SET_CARREGADO', true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function comboKpis() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().comboKpis}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit('promocoes/SET_CARREGADO', true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}


// Rotas antigas
export function listarPromocoes(filtro, pag = 1) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(`${_rotasAPI().listarPromocoes}?pagina=${pag}&quantidadePorPagina=${15}`, filtro, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit('promocoes/SET_CARREGADO', true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function buscarPromocoes(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().buscarPromocoes}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function buscarTiposMecanicas() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().buscarMecanicas}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function promocaoPromoshot() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(_rotasAPI().promocaoPromoshot, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
export function editarPromocoes(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().editarPromocoes, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: "Algo deu errado",
          tipo: "erro",
        });
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}