<template>
    <div class="formulario-item media select-cargo">
        <label class="formulario-item-label">Tipo</label>
        <multiselect label="descricao" track-by="id" v-model="selectTipo" :options="tipo" :searchable="false"
            :allow-empty="false" select-label="Selecionar" deselect-label="Remover"
            placeholder="Clique para selecionar o tipo" selected-label="Selecionado" @input="$emit('segmentacao', selectTipo)"/>
    </div>
</template>

<script>
// import { listarCargosAgrupados } from "@/services/espelho";
import Multiselect from "vue-multiselect";
export default {
    components: { Multiselect },

    data() {
        return {
            selectTipo: [],
            tipo: [
                {id: 1, descricao: 'Fechamento'},
                {id: 2, descricao: 'Parcial'}],
        };
    },
    // mounted() {
    //     listarCargosAgrupados().then((resp) => {
    //         this.cargos = resp.data;
    //     })
    // }
}
</script>

<style scoped>
.select-cargo {
    max-width: 380px;
}
</style>